
<template>
  <a
    :href="
      'serve.php?f=administration&f2=switchUser&function=switchUser&loginAs=' +
      row['c_projectOwnerID'] +
      '&redirect=runningTests'
    "
    >{{ row[header] }}</a
  >
</template>
<script>
export default {
  props: ["row", "header"],
};
</script>